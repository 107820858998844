@import 'carbon-components/scss/globals/scss/styles.scss';



html, body, #root {
    height: 100%;
}
#root {
    padding: 100px 32px;
}
h1 {
    margin-bottom: 32px;
}
.bx--form {
    padding-bottom: 100px!important;
}